import React, { useEffect, useState } from 'react';
import { FaTiktok, FaInstagram, FaYoutube, FaTwitter } from "react-icons/fa";
import '/Users/andrewgao/Desktop/Qupid/Qupid Code/CupidFirebase/qupid/src/App.css';
import AppleStoreBadge from '/Users/andrewgao/Desktop/Qupid/Qupid Code/CupidFirebase/qupid/src/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg';

const QupidLandingPage = () => {
  const [time, setTime] = useState('');
  
  useEffect(() => {
    const updateTimer = () => {
      const now = new Date();
      const day = String(now.getDate()).padStart(2, '0');
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const year = now.getFullYear();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      const milliseconds = String(now.getMilliseconds()).padStart(2, '0').slice(0, 2);
      
      setTime(`${day} ${month} ${year} ${hours}:${minutes}:${seconds}:${milliseconds}`);
      requestAnimationFrame(updateTimer);
    };
    
    updateTimer();
  }, []);
  
  return (
    <div className="qupid-page">
      <div className="timer" id="timer">{time}</div>
      <div className="content">
        <h1>Qupid</h1>
        <p>The app everyone is <span className="bold">secretly</span> using.</p>
        <a href="https://apps.apple.com/us/app/qupid/id6483940831" className="download-button" target="_blank" rel="noopener noreferrer">
          <img src={AppleStoreBadge} alt="Download on the App Store" />
        </a>
        {/* <div className="support-info">
          <p>support: support@qupid-app.com</p>
          <p>Please check out Terms & Privacy Policy below.</p>
        </div> */}
      </div>
      <footer>
        <div className="footer-links">
          <a href="mailto:support@qupid-app.com">Contact</a>
          <a href="https://bit.ly/qupidterms" target="_blank" rel="noopener noreferrer">Terms</a>
          <a href="https://bit.ly/qupidprivacypolicy" target="_blank" rel="noopener noreferrer">Privacy</a>
        </div>
        <div className="social-icons">
          <a href="https://www.tiktok.com/@qupid.app?lang=en" target="_blank" rel="noopener noreferrer"><FaTiktok /></a>
          <a href="https://www.instagram.com/qupid.app.ai/" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          <a href="https://www.youtube.com/@QupidApp" target="_blank" rel="noopener noreferrer"><FaYoutube /></a>
          <a href="https://x.com/qupid_app" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
        </div>
      </footer>
    </div>
  );
};

export default QupidLandingPage;